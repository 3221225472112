import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// data
import { branding as clientsList } from '@data/clients';

// components
import Layout from '@src/layouts';
import Link from '@components/atoms/Link';
import ButtonPrimary from '@components/atoms/buttons/ButtonPrimary';
import SectionTitle from '@components/atoms/SectionTitle';
import ContactBox from '@components/molecules/ContactBox';
import FormMessage from '@components/molecules/FormMessage';
import HeroPage from '@components/molecules/heros/HeroPage';
import ItemBullet from '@components/molecules/items/ItemBullet';
import ListCards from '@components/organisms/ListCards';
import ListClients from '@components/organisms/ListClients';
import ListRelatedProjects from '@components/organisms/ListRelatedProjects';

// assets
import imgBranding from '@assets/images/ico-branding.svg';
import iconBullet1 from '@assets/images/bullet_1.svg';
import iconBullet2 from '@assets/images/bullet_2.svg';
import iconBullet3 from '@assets/images/bullet_3.svg';
import imgBrandPositioning from '@assets/images/ico-branding-brand-positioning.svg';
import imgIllustration from '@assets/images/ico-branding-illustration.svg';
import imgLogoCreation from '@assets/images/ico-branding-logo-creation.svg';
import imgMotionDesign from '@assets/images/ico-branding-motion-design.svg';
import imgUserInterface from '@assets/images/ico-branding-user-interface.svg';
import imgVisualIdentity from '@assets/images/ico-branding-visual-identity.svg';
import imgWorkshop from '@assets/images/ico-branding-workshop.svg';
import gifEtjf from '@assets/images/projects/thumbs/PORTRAIT-ETVJ.gif';

// --------------------------------
// #region data
// --------------------------------

const language = 'fr';

const seo = {
	title: 'Agence de Branding suisse – Lausanne, Genève',
	description:
		'Agence de branding suisse à Lausanne et Genève, spécialisée en positionnement et stratégie de marque, création de logo et identité visuelle.',
};

const pageClass = 'page-branding';

const footerRelatedLinks = [
	{ title: 'Stratégie', url: '/fr/agence-strategie-digitale/' },
	{ title: 'Solutions Web', url: '/fr/agence-web-suisse/' },
];

const stepsList = [
	{
		image: iconBullet1,
		title: 'Présentez-nous votre projet',
		text: [
			'Toute belle aventure débute par une rencontre. Discutons de votre réalité pour en définir une proposition stratégique sur mesure.',
			'Présentez-nous vos besoins : logo, marque, identité…',
		],
	},
	{
		image: iconBullet2,
		title: 'Positionnons votre activité',
		text: [
			'À l’aide d’outils collaboratifs et performants, positionnons votre marque et créons une identité visuelle forte et efficace.',
			<>
				Workshops stratégiques
				<br />
				Positionnement de marque
				<br />
				Création visuelle
			</>,
		],
	},
	{
		image: iconBullet3,
		title: 'Fêtons votre succès et optimisons-le',
		text: [
			'Fêtons ensemble le succès lors de l’aboutissement de votre projet.',
			'Analysons l’impact de la performance pour en optimiser encore son potentiel.',
		],
	},
];

const cardsList = [
	{
		image: imgBrandPositioning,
		title: 'Positionnement',
		alt: 'Positionnement de marque',
		text: 'Positionnement et définition de la personnalité d’une marque par l’intermédiaire de workshops.',
		// link: { title: 'Lire plus', url: '/todo' },
	},
	{
		image: imgLogoCreation,
		title: 'Création de logo',
		alt: 'Création de logo',
		text: 'Conception et dessin d’un sigle permettant d’être reconnu au sein de votre marché.',
	},
	{
		image: imgMotionDesign,
		title: 'Motion Design',
		alt: 'Motion Design',
		text: 'Création d’animations et de micro-interactions pour les supports digitaux de votre marque.',
		// link: { title: 'Lire plus', url: '/todo' },
	},
	{
		image: imgVisualIdentity,
		title: 'Identité visuelle',
		alt: 'Identité visuelle',
		text: 'Création d’éléments graphiques et de supports permettant à votre marque de se rendre visible.',
		// link: { title: 'Lire plus', url: '/todo' },
	},
	{
		image: imgIllustration,
		title: 'Illustration',
		alt: 'Illustration',
		text: 'Réalisation d’un univers visuel propre à votre marque. De l’illustration à la définition d’une iconographie en adéquation avec vos besoins.',
	},
	{
		image: imgUserInterface,
		title: 'UI – Interface utilisateur',
		alt: 'UI – Interface utilisateur',
		text: 'Création de l’interface  digitale où l’animation, l’illustration, la typographie et autres éléments visuels composent un ensemble cohérent.',
	},
	{
		image: imgWorkshop,
		title: 'Workshops',
		alt: 'Workshops',
		text: (
			<>
				Collaborons ensemble pour plus d’efficacité. Quel est votre ADN
				de marque&nbsp;?
				<br />
				Trouvez votre ton de voix.
				<br />
				Chaque marque a une histoire.
			</>
		),
	},
];

// --------------------------------
// #endregion
// --------------------------------

const BrandingPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				projectImages: allFile(
					filter: {
						relativePath: {
							in: ["projects/thumbs/SQUARE-Ackermann.jpg"]
						}
					}
				) {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								id
								fluid(maxWidth: 620) {
									aspectRatio
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
			}
		`
	);

	data.getImageAttributes = function (dataKey, relativePath) {
		const imageAttributes = this[dataKey].edges.find(
			({ node }) => node.relativePath === relativePath
		).node.childImageSharp;

		return imageAttributes;
	};

	const projectsList = [
		{
			link: '/fr/projets/ackermann-branding/',
			image: data.getImageAttributes(
				'projectImages',
				'projects/thumbs/SQUARE-Ackermann.jpg'
			),
			title: 'Ackermann',
			subtitle: 'Branding – Positionnement de marque – Packaging',
			linkedProjects: [
				{
					link: '/fr/projets/ackermann-typographie/',
					title: 'Typographie Ackermann sur mesure',
				},
			],
		},
		{
			link: '/fr/projets/ecole-technique-de-la-vallee-de-joux/',
			image: {
				type: 'gif',
				fluid: [
					{
						src: gifEtjf,
					},
				],
				// the aspectRatio is automatically set for jpg images coming from `useStaticQuery`, but for GIFs we need to specify it to help with size calculation
				aspectRatio: 660 / 976, // width / height
			},
			title: 'Ecole Technique de la Vallée de Joux',
			subtitle: 'Identité visuelle',
		},
	];

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={props.location}
			translations={[{ uri: '/en/swiss-branding-agency/', lang: 'en' }]}
			seo={seo}
		>
			<HeroPage
				image={imgBranding}
				title={'Branding'}
				subtitle={'Agence de branding suisse – Lausanne, Genève'}
				alt="Agence de branding suisse – Lausanne, Genève"
			/>

			<section
				className="grid text-content text-center"
				aria-label="Introduction"
				data-animation-page
			>
				<h2 className="col-big">
					Positionner votre marque
					<br /> Accroître sa visibilité <br /> Développer votre
					activité
				</h2>
				<p className="p-highlight col-big">
					Positionnement &amp; Création de marque
				</p>
			</section>

			<section className="grid grid-content" aria-label="Description">
				<div className="box text-content" data-animation-page>
					<h2>
						Faites progresser votre activité en la positionnant
						correctement.
					</h2>
					<h3>Vous désirez:</h3>
					<ul className="list-two-columns list-default list-brand">
						<li>
							<p>
								Vous démarquer clairement de la
								concurrence&nbsp;?
							</p>
						</li>
						<li>
							<p>
								Développer votre activité pour qu’elle atteigne
								son plein potentiel&nbsp;?
							</p>
						</li>
						<li>
							<p>
								Bénéficier d’un coup d’avance sur l’évolution de
								votre marché&nbsp;?
							</p>
						</li>
						<li>
							<p>
								Être bien compris en bénéficiant d’une marque
								forte et évolutive&nbsp;?
							</p>
						</li>
					</ul>
					<ButtonPrimary
						className="element-left"
						href="#message"
						text="Discutons-en"
					/>
				</div>
			</section>

			<section className="grid" aria-label="Clients">
				<div
					className="box text-center text-content"
					data-animation-page
				>
					<p className="uptitle">
						Quelques collaborations fructueuses
					</p>
					<ListClients clientsList={clientsList} />
				</div>
			</section>

			<section
				className="grid grid-content text-content text-center"
				aria-label="Projects"
				data-animation-page
			>
				<p className="uptitle">Projets de Branding</p>
				<h2>Découvrez deux succès</h2>
				<ListRelatedProjects
					className={['element-spacing', 'col-big']}
					projectsList={projectsList}
				/>
			</section>

			<section aria-label="Process">
				<SectionTitle title="Processus collaboration" />
				<div className="grid">
					<div className="box text-content" data-animation-page>
						<p className="uptitle">
							Les trois étapes pour un branding réussi
						</p>
						<h2>Comment procéder&nbsp;?</h2>
						<div className="list-three-columns">
							{stepsList.map((step, index) => (
								<ItemBullet
									key={`step-${index}`}
									image={step.image}
									title={step.title}
									text={step.text}
								/>
							))}
						</div>
					</div>
				</div>
			</section>

			<section className="grid" aria-label="Contact">
				<ContactBox
					title={
						<>
							Parlez-nous
							<br />
							de votre projet
						</>
					}
					link={{ title: 'Envoyer un message', url: '#message' }}
					text={
						<>
							Ou par téléphone:{' '}
							<Link href="tel:+41216521818">
								+41 21 652 18 18
							</Link>
						</>
					}
				/>
			</section>

			<section
				className="grid grid-content text-content text-center"
				aria-label="Services"
				data-animation-page
			>
				<p className="uptitle">Domaines D'Expertise</p>
				<h2>Nos services de branding</h2>
				<ListCards modifiers="zigzag" cardsList={cardsList} />
			</section>

			<section aria-label="Engagement">
				<SectionTitle title="Point comme nous" />

				<div className="grid">
					<div className="text-content box" data-animation-page>
						<p className="uptitle">Notre engagement en 6 points</p>
						<h2>
							On s’engage en paroles,
							<br />
							mais surtout en actes
						</h2>
						<ul className="list-two-columns">
							<li>
								<h4>C’est pas la taille qui compte</h4>
								<p>
									Que vous soyez un acteur majeur ou une
									start-up qui démarre, nous considérons avant
									tout votre projet. Nous voulons le rêver
									avec vous et participer à sa réussite. C’est
									la source de notre motivation.
								</p>
							</li>
							<li>
								<h4>Sexy mais pas que…</h4>
								<p>
									Nous aimons nous inspirer des dernières
									tendances, mais ce ne sont pas elles qui
									dictent nos choix. Notre travail est dirigé
									par la recherche de performance, de
									singularité et de pérennité.
								</p>
							</li>
							<li>
								<h4>No bullshit</h4>
								<p>
									Nous ne vous vendrons la Lune que si elle
									est atteignable. Nous ne sommes pas des
									vendeurs de rêve, mais des spécialistes aux
									compétences appliquées. Notre langage est
									franc, engagé et surtout sincère. Quand nous
									disons quelque chose c’est que nous y
									croyons.
								</p>
							</li>
							<li>
								<h4>On est pas des divas</h4>
								<p>
									Nous n’allons pas imposer notre vision, ou
									nous vexer si vous pensez différemment. Bien
									sûr, nous avons un point de vue&nbsp;! Nous
									sommes convaincus de nos choix, mais ils
									sont là pour être discutés et surtout pour
									vous permettre de progresser.
								</p>
							</li>
							<li>
								<h4>Culture digitale, mais pas que</h4>
								<p>
									Une marque doit être pensée avec une
									perspective digitale, mais pas que… Nous
									sommes nés avec le digital, comprenons ses
									enjeux et participons à définir ses normes.
									Attention toutefois, cela ne signifie pas
									que nous ne savons pas faire du papier.
								</p>
							</li>
							<li>
								<h4>Proximité</h4>
								<p>
									La communication se joue aussi dans la
									relation. Nous sommes convaincus de la
									nécessité de nous rencontrer, d’initier
									ensemble des workshops stratégiques. La
									rencontre permet de réaliser des projets
									encore meilleurs.
								</p>
							</li>
						</ul>
					</div>
				</div>
			</section>

			<section className="grid" aria-label="Prendre contact" id="message">
				<FormMessage
					title={
						<>
							Comment pouvons-nous
							<br />
							vous aider dans votre projet&nbsp;?
						</>
					}
					label="Message"
					text="Pour nous contacter par email"
					contact="hello@superhuit.ch"
				/>
			</section>
		</Layout>
	);
};

export default BrandingPage;
